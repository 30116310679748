/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/

(function(f){
    if (typeof define === 'function' && define.amd) {
        define(["kendo.core"], f);
    } else {
        f();
    }
}(function(){
(function ($, undefined) {
/* FlatColorPicker messages */

if (kendo.ui.FlatColorPicker) {
kendo.ui.FlatColorPicker.prototype.options.messages =
$.extend(true, kendo.ui.FlatColorPicker.prototype.options.messages,{
  "apply": "Potvrdit",
  "cancel": "Zru�it"
});
}

/* ColorPicker messages */

if (kendo.ui.ColorPicker) {
kendo.ui.ColorPicker.prototype.options.messages =
$.extend(true, kendo.ui.ColorPicker.prototype.options.messages,{
  "apply": "Potvrdit",
  "cancel": "Zru�it"
});
}

/* ColumnMenu messages */

if (kendo.ui.ColumnMenu) {
kendo.ui.ColumnMenu.prototype.options.messages =
$.extend(true, kendo.ui.ColumnMenu.prototype.options.messages,{
  "sortAscending": "Roustouc�",
  "sortDescending": "Klesaj�c�",
  "filter": "Filtr",
  "columns": "Sloupce",
  "done": "Hotovo",
  "settings": "Nastaven� sloupcu",
  "lock": "Zamknout",
  "unlock": "Odemknout"
});
}

/* Editor messages */

if (kendo.ui.Editor) {
kendo.ui.Editor.prototype.options.messages =
$.extend(true, kendo.ui.Editor.prototype.options.messages,{
  "bold": "Tucne",
  "italic": "Kurz�va",
  "underline": "Podtr�en�",
  "strikethrough": "Pre�krtnut�",
  "superscript": "Horn� index",
  "subscript": "Doln� index",
  "justifyCenter": "Zarovnat na stred",
  "justifyLeft": "Zarovnat vlevo",
  "justifyRight": "Zarovnat vpravo",
  "justifyFull": "Zarovnat do bloku",
  "insertUnorderedList": "Vlo�it odr�kov� seznam",
  "insertOrderedList": "Vlo�it c�slovan� seznam",
  "indent": "Zvet�it odsazen�",
  "outdent": "Zmen�it odsazen�",
  "createLink": "Vlo�it odkaz",
  "unlink": "Zru�it odkaz",
  "insertImage": "Vlo�it obr�zek",
  "insertFile": "Vlo�it soubor",
  "insertHtml": "Vlo�it HTML",
  "viewHtml": "Zobrazit HTML",
  "fontName": "Vyberte p�smo",
  "fontNameInherit": "(v�choz� p�smo)",
  "fontSize": "Vyberte velikost p�sma",
  "fontSizeInherit": "(v�choz� velikost)",
  "formatBlock": "Form�t",
  "formatting": "Form�tov�n�",
  "foreColor": "Barva",
  "backColor": "Barva pozad�",
  "styles": "Styly",
  "emptyFolder": "Pr�zn� adres�r",
  "uploadFile": "Nahr�t",
  "orderBy": "Seradit dle:",
  "orderBySize": "Velikosti",
  "orderByName": "Jm�na",
  "invalidFileType": "Vybran� soubor s pr�ponou \"{0}\" nen� podporovan�. Podporovan� soubory jsou {1}.",
  "deleteFile": "Opravdu chcete smazat \"{0}\"?",
  "overwriteFile": "'Soubor s n�zvem \"{0}\" ji� ve vybran�m adres�ri existuje. Prejete si jej prepsat?",
  "directoryNotFound": "Adres�r zadan�ho n�zvu nebyl nalezen.",
  "imageWebAddress": "Odkaz",
  "imageAltText": "Alternativn� text",
  "imageWidth": "��rka (px)",
  "imageHeight": "V��ka (px)",
  "fileWebAddress": "Web adresa",
  "fileTitle": "N�zev",
  "linkWebAddress": "Odkaz",
  "linkText": "Text",
  "linkToolTip": "Text po najet�",
  "linkOpenInNewWindow": "Otevr�t odkaz v nov�m okne",
  "dialogUpdate": "Aktualizovat",
  "dialogInsert": "Vlo�it",
  "dialogButtonSeparator": "nebo",
  "dialogCancel": "Zru�it",
  "createTable": "Vlo�it tabulku",
  "addColumnLeft": "Pridat sloupec vlevo",
  "addColumnRight": "Pridat sloupec vpravo",
  "addRowAbove": "Pridat r�dek nad",
  "addRowBelow": "Pridat r�dek pod",
  "deleteRow": "Smazat r�dek",
  "deleteColumn": "Smazat soupec"
});
}

/* FileBrowser messages */

if (kendo.ui.FileBrowser) {
kendo.ui.FileBrowser.prototype.options.messages =
$.extend(true, kendo.ui.FileBrowser.prototype.options.messages,{
  "uploadFile": "Nahr�t",
  "orderBy": "Seradit podle",
  "orderByName": "N�zev",
  "orderBySize": "Velikost",
  "directoryNotFound": "Adres�r s t�mto n�zvem nebyl nalezen.",
  "emptyFolder": "Pr�zdn� slo�ka",
  "deleteFile": 'Jste si jist�, �e chcete smazat "{0}"?',
  "invalidFileType": "Soubor \"{0}\" nen� platn�. Pou�iteln� typy souboru {1}.",
  "overwriteFile": "Soubor \"{0}\" ji� v aktu�ln�m adres�ri existuje. Prejete si jej prepsat?",
  "dropFilesHere": "pret�hnete soubory pro nahr�n�",
  "search": "Hledat"
});
}

/* FilterCell messages */

if (kendo.ui.FilterCell) {
kendo.ui.FilterCell.prototype.options.messages =
$.extend(true, kendo.ui.FilterCell.prototype.options.messages,{
  "isTrue": "je pravda",
  "isFalse": "nen� pravda",
  "filter": "Filtrovat",
  "clear": "Zru�it",
  "operator": "Oper�tor"
});
}

/* Filter cell operators */

if (kendo.ui.FilterCell) {
kendo.ui.FilterCell.prototype.options.operators =
$.extend(true, kendo.ui.FilterCell.prototype.options.operators,{
  "string": {
    "eq": "Je shodn� s",
    "neq": "Je ruzn� od",
    "startswith": "Zac�n� na",
    "contains": "Obsahuje",
    "doesnotcontain": "Neobsahuje",
    "endswith": "Konc� na"
  },
  "date": {
    "eq": "Je rovno",
    "neq": "Je ruzn� od",
    "gt": "Vet��",
    "gte": "Vet�� nebo rovn�",
    "lt": "Men��",
    "lte": "Men�� nebo rovn�"
  },
  "number": {
    "eq": "Je rovno",
    "gt": "Je vet�� ne�",
    "gte": "Je vet�� nebo rovno",
    "lt": "Je men�� ne�",
    "lte": "Je men�� nebo rovno",
    "neq": "Je ruzn� od"
  },
  "enums": {
    "eq": "Je rovno",
    "neq": "Je ruzn� od"
  }
});
}

/* FilterMenu messages */

if (kendo.ui.FilterMenu) {
kendo.ui.FilterMenu.prototype.options.messages =
$.extend(true, kendo.ui.FilterMenu.prototype.options.messages,{
  "and": "A z�roven",
  "clear": "Zru�it",
  "filter": "Filtrovat",
  "info": "Zobrazit polo�ky s hodnotou, kter�:",
  "isFalse": "nen� pravda",
  "isTrue": "je pravda",
  "or": "Nebo",
  "selectValue": "-Vyberte hodnotu-",
  "cancel": "Zru�it",
  "operator": "Oper�tor",
  "value": "Hodnota"
});
}

/* Filter menu operator messages */

if (kendo.ui.FilterMenu) {
kendo.ui.FilterMenu.prototype.options.operators =
$.extend(true, kendo.ui.FilterMenu.prototype.options.operators,{
  "date": {
    "eq": "Je rovno",
    "gt": "Vet��",
    "gte": "Vet�� nebo rovn�",
    "lt": "Men��",
    "lte": "Men�� nebo rovn�",
    "neq": "Je ruzn� od"
  },
  "number": {
    "eq": "Je rovno",
    "gt": "Je vet�� ne�",
    "gte": "Je vet�� nebo rovno",
    "lt": "Je men�� ne�",
    "lte": "Je men�� nebo rovno",
    "neq": "Je ruzn� od"
  },
  "string": {
    "contains": "Obsahuje",
    "doesnotcontain": "Neobsahuje",
    "endswith": "Konc� na",
    "eq": "Je shodn� s",
    "neq": "Je ruzn� od",
    "startswith": "Zac�n� na"
  },
  "enums": {
    "eq": "Je rovno",
    "neq": "Je ruzn� od"
  }
});
}

/* FilterMultiCheck messages */

if (kendo.ui.FilterMultiCheck) {
kendo.ui.FilterMultiCheck.prototype.options.messages =
$.extend(true, kendo.ui.FilterMultiCheck.prototype.options.messages,{
  "checkAll": "Zvolit v�e",
  "clear": "Vymazat",
  "filter": "Filtr",
  "search": "Hledat"
});
}

/* Gantt messages */

if (kendo.ui.Gantt) {
kendo.ui.Gantt.prototype.options.messages =
$.extend(true, kendo.ui.Gantt.prototype.options.messages,{
  "actions": {
    "addChild": "Pridat potomka",
    "append": "Pridat �kol",
    "insertAfter": "Pridat pod",
    "insertBefore": "Pridat nad",
    "pdf": "Export do PDF"
  },
  "cancel": "Zru�it",
  "deleteDependencyWindowTitle": "Smazat z�vislost",
  "deleteTaskWindowTitle": "Smazat �kol",
  "destroy": "Smazat",
  "editor": {
    "assingButton": "Priradit",
    "editorTitle": "�kol",
    "end": "Konec",
    "percentComplete": "Hotovo",
    "resources": "Zdroje",
    "resourcesEditorTitle": "Zdroje",
    "resourcesHeader": "Zdroje",
    "start": "Zac�tek",
    "title": "N�zev",
    "unitsHeader": "Jednotky"
  },
  "save": "Ulo�it",
  "views": {
    "day": "Den",
    "end": "Konec",
    "month": "Mes�c",
    "start": "Zac�tek",
    "week": "T�den",
    "year": "Rok"
  }
});
}

/* Grid messages */

if (kendo.ui.Grid) {
kendo.ui.Grid.prototype.options.messages =
$.extend(true, kendo.ui.Grid.prototype.options.messages,{
  "commands": {
    "cancel": "Zru�it",
    "canceledit": "Zru�it",
    "create": "Pridat nov� z�znam",
    "destroy": "Smazat",
    "edit": "Upravit",
    "excel": "Excel export",
    "pdf": "PDF export",
    "save": "Ulo�it zmeny",
    "select": "Vybrat",
    "update": "Ulo�it"
  },
  "editable": {
    "confirmation": "Opravdu chcete smazat tento z�znam?",
    "cancelDelete": "Zru�it",
    "confirmDelete": "Smazat"
  },
  "noRecords": "��dn� z�znam nenalezen."
});
}

/* Groupable messages */

if (kendo.ui.Groupable) {
kendo.ui.Groupable.prototype.options.messages =
$.extend(true, kendo.ui.Groupable.prototype.options.messages,{
  "empty": "Pret�hnete sem z�hlav� sloupce pro seskupen� dle vybran�ho sloupce."
});
}

/* NumericTextBox messages */

if (kendo.ui.NumericTextBox) {
kendo.ui.NumericTextBox.prototype.options =
$.extend(true, kendo.ui.NumericTextBox.prototype.options,{
  "upArrowText": "Zvet�it",
  "downArrowText": "Zmen�it"
});
}

/* Pager messages */

if (kendo.ui.Pager) {
kendo.ui.Pager.prototype.options.messages =
$.extend(true, kendo.ui.Pager.prototype.options.messages,{
  "allPages": "All",
  "display": "{0} - {1} z {2} celkem",
  "empty": "��dn� z�znam nenalezen",
  "first": "Na prvn� str�nku",
  "itemsPerPage": "z�znamu na str�nku",
  "last": "Na posledn� str�nku",
  "next": "Na dal�� str�nku",
  "of": "z {0}",
  "page": "Strana",
  "previous": "Na predchoz� str�nku",
  "refresh": "Obnovit",
  "morePages": "Dal�� str�nky"
});
}

/* PivotGrid messages */

if (kendo.ui.PivotGrid) {
kendo.ui.PivotGrid.prototype.options.messages =
$.extend(true, kendo.ui.PivotGrid.prototype.options.messages,{
  "measureFields": "Sem pret�hnete pole",
  "columnFields": "Sem pret�hnete sloupce",
  "rowFields": "Sem pret�hnete r�dky"
});
}

/* PivotFieldMenu messages */

if (kendo.ui.PivotFieldMenu) {
kendo.ui.PivotFieldMenu.prototype.options.messages =
$.extend(true, kendo.ui.PivotFieldMenu.prototype.options.messages,{
  "info": "Zobrazit polo�ky s hodnotou:",
  "filterFields": "Filtr",
  "filter": "Filtr",
  "include": "Zahrnout pole...",
  "title": "Pole k zahrnut�",
  "clear": "Vycistit",
  "ok": "Ok",
  "cancel": "Zru�it",
  "operators": {
    "contains": "Obsahuje",
    "doesnotcontain": "Neobsahuje",
    "startswith": "Zac�na na",
    "endswith": "Konc� na",
    "eq": "Je rovno",
    "neq": "Nen� rovno"
  }
});
}

/* RecurrenceEditor messages */

if (kendo.ui.RecurrenceEditor) {
kendo.ui.RecurrenceEditor.prototype.options.messages =
$.extend(true, kendo.ui.RecurrenceEditor.prototype.options.messages,{
  "frequencies": {
    "daily": "Denne",
    "monthly": "Mes�cne",
    "never": "Nikdy",
    "weekly": "T�dne",
    "yearly": "Rocne"
  },
  "hourly": {
    "repeatEvery": "Opakovat ka�d�ch: ",
    "interval": " hodin"
  },
  "daily": {
    "interval": "dn�",
    "repeatEvery": "Opakovat ka�d�:"
  },
  "weekly": {
    "repeatEvery": "Opakovat ka�d�:",
    "repeatOn": "Opakovat v:",
    "interval": "t�den(ny)"
  },
  "monthly": {
    "day": "Den",
    "interval": "mes�c(e)",
    "repeatEvery": "Opakovat ka�d�:",
    "repeatOn": "Opakovat v:"
  },
  "yearly": {
    "of": "",
    "repeatEvery": "Opakovat ka�d�:",
    "repeatOn": "Opakovat v:",
    "interval": "rok(y)"
  },
  "end": {
    "after": "Konec po",
    "occurrence": "opakov�n�",
    "label": "Konec:",
    "never": "Nikdy",
    "on": "Dne",
    "mobileLabel": "Konc�"
  },
  "offsetPositions": {
    "first": "prvn�",
    "fourth": "ctvrt�",
    "last": "posledn�",
    "second": "druh�",
    "third": "tret�"
  },

  "weekdays": {
    "day": "den",
    "weekday": "pracovn� den",
    "weekend": "v�kend"
  }
});
}

/* Scheduler messages */

if (kendo.ui.Scheduler) {
kendo.ui.Scheduler.prototype.options.messages =
$.extend(true, kendo.ui.Scheduler.prototype.options.messages,{
  "allDay": "cel� den",
  "cancel": "Zru�it",
  "save": "Ulo�it",
  "time": "Cas",
  "today": "Dnes",
  "date": "Datum",
  "destroy": "Smazat",
  "event": "Ud�lost",
  "deleteWindowTitle": "Smazat ud�lost",
  "showFullDay": "Zobrazit cel� den",
  "showWorkDay": "Zobrazit pracovn� dobu",
  "ariaSlotLabel": "Zvoleno od {0:t} do {1:t}",
  "ariaEventLabel": "{0} dne {1:D} v {2:t}",
  "editable": {
    "confirmation": "Opravdu chcete smazat tuto ud�lost?"
  },
  "views": {
    "day": "Den",
    "week": "T�den",
    "workWeek": "Pracovn� t�den",
    "month": "Mes�c",
    "agenda": "Agenda"
  },
  "recurrenceMessages": {
    "deleteWindowOccurrence": "Smazat vybranou ud�lost",
    "deleteWindowSeries": "Smazat v�e",
    "deleteWindowTitle": "Smazat opakovanou ud�lost",
    "editWindowOccurrence": "Upravit jen vybranou ud�lost",
    "editWindowSeries": "Upravit v�e",
    "editWindowTitle": "Upravit opakuj�c� se ud�lost",
    "deleteRecurring": "Chcete smazat jen vybranou ud�lost, nebo i v�echna opakov�n�?",
    "editRecurring": "Chcete upravit jen vybranou ud�lost, nebo i v�echna opakov�n�?"
  },
  "editor": {
    "title": "Nadpis",
    "start": "Zac�tek",
    "end": "Konec",
    "allDayEvent": "Celodenn�",
    "description": "Popis",
    "repeat": "Opakov�n�",
    "timezone": " ",
    "startTimezone": "Casov� p�smo zac�tku",
    "endTimezone": "Casov� p�smo konce",
    "separateTimezones": "Ruzn� casov� p�sma pro zac�tek a konec",
    "timezoneEditorTitle": "Casov� p�sma",
    "timezoneEditorButton": "Casov� p�smo",
    "timezoneTitle": "Casov� p�sma",
    "noTimezone": "��dn� casov� p�smo",
    "editorTitle": "Ud�lost"
  }
});
}

/* Slider messages */

if (kendo.ui.Slider) {
kendo.ui.Slider.prototype.options =
$.extend(true, kendo.ui.Slider.prototype.options,{
  "increaseButtonTitle": "Zv��it",
  "decreaseButtonTitle": "Sn�it"
});
}

/* TreeList messages */

if (kendo.ui.TreeList) {
kendo.ui.TreeList.prototype.options.messages =
$.extend(true, kendo.ui.TreeList.prototype.options.messages,{
  "noRows": "��dn� z�znamy k zobrazen�",
  "loading": "Nac�t�m...",
  "requestFailed": "Po�adavek selhal.",
  "retry": "Zkusit znovu",
  "commands": {
      "edit": "Upravit",
      "update": "Aktualizovat",
      "canceledit": "Zru�it",
      "create": "Pridat nov� z�znam",
      "createchild": "Pridat nov� z�znam",
      "destroy": "Smazat",
      "excel": "Excel export",
      "pdf": "PDF export"
  }
});
}

/* TreeView messages */

if (kendo.ui.TreeView) {
kendo.ui.TreeView.prototype.options.messages =
$.extend(true, kendo.ui.TreeView.prototype.options.messages,{
  "loading": "Nac�t�m...",
  "requestFailed": "Po�adavek selhal.",
  "retry": "Zkusit znovu"
});
}

/* Upload messages */

if (kendo.ui.Upload) {
kendo.ui.Upload.prototype.options.localization =
$.extend(true, kendo.ui.Upload.prototype.options.localization,{
  "select": "Vyberte...",
  "cancel": "Zru�it",
  "retry": "Zkusit znova",
  "remove": "Smazat",
  "uploadSelectedFiles": "Nahr�t soubory",
  "dropFilesHere": "Pro nahr�n� pret�hnete soubory sem",
  "statusUploading": "nahr�v�m",
  "statusUploaded": "nahr�no",
  "statusWarning": "varov�n�",
  "statusFailed": "chyba",
  "headerStatusUploading": "Nahr�v�m...",
  "headerStatusUploaded": "Hotovo"
});
}

/* Validator messages */

if (kendo.ui.Validator) {
kendo.ui.Validator.prototype.options.messages =
$.extend(true, kendo.ui.Validator.prototype.options.messages,{
  "required": "{0} je povinn�",
  "pattern": "{0} nen� platn�",
  "min": "{0} mus� b�t vet�� ne� rovno {1}",
  "max": "{0} mus� b�t men�� nebo rovno {1}",
  "step": "{0} nen� platn�",
  "email": "{0} nen� platn� e-mailov� adresa",
  "url": "{0} nen� platn� webov� adresa",
  "date": "{0} nen� platn� datum",
  "dateCompare": "Datum konce mus� b�t vy��� ne� nebo rovno datumu zac�tku"
});
}
})(window.kendo.jQuery);
}));